import { generateError } from "../utils/form.utils";
import { financeErrorValidator } from "@app/pages/financials/shared/const/finance-error-message.const";

export const requiredMessage = 'This field is required';

export const ROAM_VALIDATORS: any = {
  ...financeErrorValidator,
  completionDate: generateError('required', requiredMessage),
  inspectionDate: generateError('required', requiredMessage),
  name: generateError('required', requiredMessage),
  startDate: generateError('required', requiredMessage),
  unitId: generateError('required', requiredMessage),
  cardholder: generateError('required', requiredMessage),
}


export const ROAM_MESSAGES = {
  savedData: 'Data has been saved',
  error: 'An error occurred while saving the data. Please try again.'
}
